
const calculatePayment = (price, downPayment, annualRate, term) => {
    let downPaymentRate = downPayment / 100;
    let i = (annualRate / 12.0) / 100;
    let downPaymentAmount = downPaymentRate * price;
    let principal = price - downPaymentAmount;
    let n = term * 12;
    let payment = principal * (i + (i / (Math.pow(1 + i, n) - 1)));

    return payment.toFixed(2);
}

const calculateAmortizationTable = (payment, amountDue, monthlyRate, terms) => {
    const amortTable = [];
    for (let i = 0; i < terms; i++) {
        let interest = monthlyRate * amountDue;
        let principle = payment - interest;
        amountDue = amountDue - principle;
        amortTable.push([interest, principle, amountDue]);
    }

    return amortTable;
}

export {calculatePayment, calculateAmortizationTable};