import NumberFormat from "react-number-format";
import React from "react";
import styled from "styled-components";

const isNum = (v) => (v && !isNaN(v));
const isPercent = (v) => (v && isNum(v) && v <= 1.0 && v >= 0.01);

const Field = styled.div`
    input {
        width: 100%;
        font-size: 24px;
        text-align: right;
        border: none;
        border-bottom: 1px solid black;
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        :[type=number] {
            -moz-appearance: textfield;
        }

        :focus {
            outline: none;
        }
    }
`;

const PercentField = (props) => {
    const { name, formik } = props;
    const value = formik.values[name];

    return (
        <Field>
            <NumberFormat
                suffix=" %"
                thousandSeparator={true}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={values => {
                    formik.setFieldValue(name, values.floatValue);
                }}
            />
        </Field>
    );
}

const CurrencyField = (props) => {
    const { name, min = 0, formik } = props;
    const value = formik.values[name];

    return (
        <Field>
            <NumberFormat
                prefix="$"
                thousandSeparator={true}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={values => {
                    formik.setFieldValue(name, values.floatValue);
                }}
                isAllowed={values => {
                    return min <= values.floatValue;
                }}
            />
        </Field>

    );
}

const YearsField = (props) => {
    const { name, min = 1, max = 50, formik } = props;
    const value = formik.values[name];

    return (
        <Field>
            <NumberFormat
                value={value}
                min={min}
                max={max}
                step="1"
                suffix=" years"
                thousandSeparator={true}
                fixedDecimalScale={true}
                onValueChange={values => {
                    formik.setFieldValue(name, values.floatValue);
                }}
                isAllowed={values => {
                    return min <= values.floatValue && values.floatValue <= max;
                }}
            />
        </Field>
    );
}


export { YearsField, PercentField, CurrencyField };