import React, { useState, useEffect } from "react";
import { useFormik, Field } from "formik";
import { calculatePayment, calculateAmortizationTable } from "../utils/loan";
import { PercentField, CurrencyField, YearsField } from "../components/Fields";
import styled from "styled-components"
import Column from "./Column";
import Title from "./Title";
import SizedBox from "./SizedBox";
import NumberFormat from "react-number-format";

const Table = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: 16px;
    width: 100%;
`;

const TableForm = styled.form`
    width: 100%;
`;

const FormattedInputSection = styled.div`
    @media (max-width: 640px) {
        padding: 8px;
        align-self: flex-end;
    } 
    width: 100%;
    align-self: flex-start;
`;

const TableItem = styled(Column)`
    text-align: left;
    > * {
        margin: 8px;
    }
    max-width: 100%;
`;

const Payment = styled.div`
    align-self: flex-end;
    > span {
        color: #4CAF50;
        font-weight: bold;
        font-size: 32px;
    }
`;

const MortgageTable = (props) => {
    const {
        onTableChange = (amortTable) => { },
        onPaymentChange = (payment) => { },
        onPriceChange = (price) => { },
        onYearsChange = (years) => { }
    } = props;

    const [payment, setPayment] = useState(1.0);
    const [amortTable, setAmortTable] = useState([[1, 1, 0]]);
    const [price, setPrice] = useState(400000);
    const [years, setYears] = useState(0);

    useEffect(() => {
        onPaymentChange(payment);
    }, [payment]);

    useEffect(() => {
        onTableChange(amortTable);
    }, [amortTable]);

    useEffect(() => {
        onPriceChange(price);
    }, [price]);

    useEffect(() => {
        onYearsChange(years);
    }, [years])

    const formik = useFormik({
        initialValues: {
            "annualInterestRate": 2.00,
            "downPayment": 20.0,
            "price": price,
            "years": 30
        },
        validateOnMount: true,
        validate: (values) => {
            setPrice(values.price);
            setYears(values.years);

            const amountDue = (1 - (values.downPayment / 100)) * values.price;
            const payment = calculatePayment(values.price, values.downPayment, values.annualInterestRate, values.years);

            setAmortTable(calculateAmortizationTable(payment, amountDue, values.annualInterestRate / 1200, values.years * 12));
            setPayment(payment);
        }
    });

    return (
        <FormattedInputSection>
            <TableForm onSubmit={formik.handleSubmit}>
                <Table>
                    <TableItem crossAxis="flex-start">
                        <Title>Down Payment</Title>
                        <PercentField formik={formik} name="downPayment" />
                    </TableItem>

                    <TableItem crossAxis="flex-start">
                        <Title>Price</Title>
                        <CurrencyField formik={formik} name="price" />
                    </TableItem>

                    <TableItem crossAxis="flex-start">
                        <Title>Years</Title>
                        <YearsField formik={formik} name="years" />
                    </TableItem>

                    <TableItem crossAxis="flex-start">
                        <Title>Apr</Title>
                        <PercentField formik={formik} name="annualInterestRate" />
                    </TableItem>
                    <TableItem crossAxis="flex-start">
                        <Title>Home Payment</Title>
                        <Payment>
                            <NumberFormat prefix="$" thousandSeparator={true} value={payment} displayType="text" decimalScale={2} fixedDecimalScale={true} />
                        </Payment>
                    </TableItem>
                </Table>
            </TableForm>
        </FormattedInputSection>
    );
}

export default MortgageTable;