import React from "react";
import styled from "styled-components";


const SliderBar = styled.input`
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 4px;  
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin: 0;

    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 24px;
        height: 24px;
        border-radius: 50%; 
        background: #4CAF50;
        cursor: pointer;
    }

    ::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
    }
`;

const Slider = (props) => {

    const {
        min,
        max,
        startingValue,
        onChange = (v) => { console.log(v) }
    } = props;

    return (
        <SliderBar type="range" min={min} max={max} onChange={(e) => { onChange(e.target.value); e.preventDefault(); }} />
    );
}

export default Slider;