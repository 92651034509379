import styled from "styled-components"
import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import Slider from "../components/Slider";
import Layout from "../components/Layout";
import MortgageTable from "../components/MortgageTable";
import Column from "../components/Column";
import Title from "../components/Title";
import SizedBox from "../components/SizedBox";

const Main = styled(Column)`
    width: 100%;
    > * {
        margin-bottom: 32px;
    } 
`;

const PageTitle = styled.h2`
    align-self: ${props => props.alignSelf || "flex-start"};
`;

const PieCharts = styled.div`
    width: 100%;
    align-self: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(0, 400px));
`;

const PieChartContainer = styled.div`
    max-width: 400px;
`;

const Disclaimer = styled.p`
    opacity: 0.5;
`;

const PieChartPlaceholder = styled.canvas`
    width: 360px;
    max-height: 480px;
`;

const Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]


const Home = () => {
    const paymentBreakdownRef = useRef(null);
    const amountLeftRef = useRef(null);

    const [payment, setPayment] = useState(1.0);
    const [amortTable, setAmortTable] = useState([[1, 1, 0]]);
    const [price, setPrice] = useState(400000);
    const [currMonth, setCurrMonth] = useState(0);
    const [years, setYears] = useState(0);
    const [breakDownChart, setBreakDownChart] = useState(null);
    const [amountLeftChart, setAmountLeftChart] = useState(null);


    const intl = useIntl();

    useEffect(() => {
        setBreakDownChart(new Chart(paymentBreakdownRef.current.getContext("2d"), {
            type: 'pie',
            legend: {
                display: true
            },
            data: {
                label: "Payment Breakdown",
                datasets: [{
                    data: [0, 0],
                    backgroundColor: ["#fab1a0", "#74b9ff"],
                }],

                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: [
                    'Interest',
                    'Principle',
                ]
            }

        }));

        setAmountLeftChart(new Chart(amountLeftRef.current.getContext("2d"), {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [0, 0],
                    backgroundColor: ["#fab1a0", "#74b9ff"],
                }],
                labels: ["Amount left", "Paid off"]
            }

        }));
    }, []);

    useEffect(() => {
        if (breakDownChart && amountLeftChart) {
            const [interest, principal, amountDue] = amortTable[currMonth];
            breakDownChart.data.datasets[0].data = [interest, principal];
            amountLeftChart.data.datasets[0].data = [amountDue, price - amountDue];

            breakDownChart.update(0);
            amountLeftChart.update(0);
        }
    }, [amortTable, currMonth, price, breakDownChart, amountLeftChart]);


    return (
        <Layout>
            <Main id="main" crossAxis="center">
                <PageTitle>
                    How does your home payment break down?
                </PageTitle>
                <p>
                    <FormattedMessage id="calc.why" />
                </p>
                <MortgageTable
                    onPriceChange={(price) => setPrice(price)}
                    onPaymentChange={(payment) => setPayment(payment)}
                    onTableChange={(table) => setAmortTable(table)}
                    onYearsChange={(years) => setYears(years)}
                />
                <Title alignSelf="flex-start">
                    Year: {Math.floor(currMonth / 12)} &nbsp; Month: {Months[currMonth % 12]}
                </Title>
                <p>
                    <FormattedMessage id="calc.slider" />
                </p>
                <Slider min={1} max={years * 12 || 360} onChange={(v) => setCurrMonth(v - 1)} />
                <PieCharts>
                    <PieChartContainer>
                        <PieChartPlaceholder ref={paymentBreakdownRef} />
                    </PieChartContainer>
                    <PieChartContainer>
                        <PieChartPlaceholder ref={amountLeftRef} />
                    </PieChartContainer>
                </PieCharts>
                <Disclaimer>
                    <FormattedMessage id="calc.disclaimer" />
                </Disclaimer>
            </Main>
        </Layout>
    )
}

export default Home;